import { mapState } from "vuex";
import { goToLogin } from "@/utils/user";
import { fmoney } from "@/utils/tools.js";
import { Uploader, ImagePreview, showConfirmDialog } from "vant";
import { OSSUpload } from "@/utils/apiBase.js";
import { sendProjectIntention } from "@api/index";
import { GoBack } from "@/utils/tools";
import { mdToast } from "../../../utils/tools";

// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    Uploader
  },
  data() {
    // 这里存放数据
    return {
      //产品描述
      title: "",
      titleInputShow: false,
      autosizeData: {
        minHeight: 150
      },
      //产品图片
      max_count: 20,
      image_list: [],
      //金额
      money: "",
      formatter: value => value ? fmoney(value, 0) : "",
      // 是否填完
      isComplete: false
    };
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState({
      ifLogin: state => state.user.ifLogin // 是否登录
    })
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {},
  // 生命周期 - 创建之前
  beforeMount() {},
  // 生命周期 - 挂载之前
  beforeUpdate() {},
  // 生命周期 - 更新之前
  updated() {},
  // 生命周期 - 更新之后
  activated() {},
  // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    //展示文字输入弹窗
    showTitleInput() {
      if (!this.ifLogin) {
        goToLogin();
        return;
      }
      this.titleInputShow = true;
    },
    // 文字输入监听
    titleInputAction() {
      this.checkComplete();
    },
    // 选择照片
    async afterRead(event) {
      console.log('event---', event);
      let fileList = [];
      if (event.length) {
        event.forEach(item => {
          fileList.push(item.file);
        });
      } else {
        fileList = [event.file];
      }
      const current_img_length = this.image_list.length;
      let that = this;
      let MDOSSoption = {
        success: function (item, idx) {
          that.onSuccess({
            item,
            idx: current_img_length + idx
          });
        },
        error: function (e, msg) {
          mdToast({
            type: "text",
            message: msg,
            onClose: () => {}
          });
        }
      };
      OSSUpload(MDOSSoption, fileList);
    },
    onSuccess(item) {
      this.image_list[item.idx] = item.item;
      this.checkComplete();
    },
    //删除照片
    delImg(index) {
      this.image_list.splice(index, 1);
      this.checkComplete();
    },
    //查看大图
    showPic(index) {
      ImagePreview({
        images: this.image_list,
        startPosition: index
      });
    },
    // 金额输入监听
    moneyInputAction() {
      this.checkComplete();
    },
    checkComplete() {
      if (this.title && this.title.length && this.image_list && this.image_list.length && this.money) {
        this.isComplete = true;
      } else {
        this.isComplete = false;
      }
    },
    async sendApply() {
      //未登录-要求登录
      if (!this.ifLogin) {
        goToLogin();
      }
      if (this.isComplete == true) {
        const {
          status,
          message
        } = await sendProjectIntention({
          content: this.title,
          imgs: JSON.stringify(this.image_list),
          goal: this.money.replace(/,/g, "")
        });
        if (status == 0) {
          showConfirmDialog({
            title: '恭喜您，已提交成功',
            cancelButtonText: '关闭弹窗',
            confirmButtonText: '离开页面',
            confirmButtonColor: '#00cba6'
          }).then(() => {
            GoBack();
          });
        } else {
          mdToast({
            type: "text",
            message: message,
            onClose: () => {}
          });
        }
      }
    },
    goBack() {
      GoBack();
    }
  }
};